import React, { useEffect } from 'react';
import Box from '@rexlabs/box';
import { Heading } from '@rexlabs/text';
import { PrimaryButton } from '@rexlabs/button';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { navigation, track } from '@rexlabs/analytics';

import ButtonTextOverflow from 'src/components/button-text-overflow';
import Footer from 'src/components/footer';
import { usePersistedState } from 'src/hooks/use-persisted-state';

const defaultStyles = StyleSheet({
  grey: {
    color: ({ token }) => token('palette.grey.500')
  },

  bold: {
    fontWeight: ({ token }) => token('typography.weight.bold')
  }
});

export function NotAvailableScreen({ openHome, activeEvent }) {
  const s = useStyles(defaultStyles);

  const [user] = usePersistedState('@checkInApp/checkedInUser');

  useEffect(() => {
    navigation({
      page: '/check-in-not-available',
      activeEvent,
      openHome,
      user
    });
    document.title = 'Check in unavailable';
  }, []); // eslint-disable-line

  return (
    <Box flexDirection='column' flex='1' justifyContent='center'>
      <Box flexDirection='column' sy='2.4rem'>
        <Box mb='2.4rem'>
          <Heading level={2} {...s('bold')}>
            {openHome?.address?.formats?.street_name_number_w_suburb}
          </Heading>
          <Heading level={3} {...s('grey')}>
            {!activeEvent
              ? 'You can only check in within 60 minutes before or after a scheduled open home for this listing'
              : 'Check in is not currently available'}
          </Heading>
        </Box>

        <ButtonTextOverflow>
          <PrimaryButton
            as='a'
            href={openHome?.ebrochure_custom_link || openHome?.ebrochure_link}
            target='_blank'
            rel='noreferrer nofollow'
            onClick={() =>
              track({
                eventName: 'Ebrouchure clicked',
                data: {
                  action: 'ebrouchure_clicked',
                  section: '/check-in-not-available',
                  activeEvent,
                  openHome,
                  user
                }
              })
            }
          >
            More info about {openHome?.address?.formats?.street_name_number}
          </PrimaryButton>
        </ButtonTextOverflow>
      </Box>

      <Footer openHome={openHome} />
    </Box>
  );
}
