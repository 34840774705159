import React, { useMemo, useState, useCallback } from 'react';
import { useModelState } from '@rexlabs/model-generator';
import Box from '@rexlabs/box';
import { StylesProvider } from '@rexlabs/styling';

import { sessionModel } from 'data/models/custom/session';
import { usePersistedState } from 'src/hooks/use-persisted-state';
import { setLightness, getContrastColor } from 'src/utils/color';

import { CheckInScreen } from 'src/features/check-in/screens/check-in-screen';
import { SuccessScreen } from 'src/features/check-in/screens/success-screen';
import { NotAvailableScreen } from 'src/features/check-in/screens/not-available-screen';
import { CheckedInScreen } from 'src/features/check-in/screens/checked-in-screen';

export function AppLayout() {
  const [deviceCheckedIn, setDeviceCheckedIn] = usePersistedState(
    '@checkInApp/deviceCheckedIn'
  );

  // TODO: fix types - https://app.shortcut.com/rexlabs/story/60659
  const { openHome, activeEvent, privacyPolicy } = useModelState(
    sessionModel
  ) as any;

  const [step, setStep] = useState(() => {
    // Checking in at the same address will expire after 8 hours
    if (
      deviceCheckedIn &&
      deviceCheckedIn.address ===
        openHome.address.formats.street_name_number_w_suburb
    ) {
      const checkInExpiry = new Date(
        new Date(deviceCheckedIn.time).getTime() + 1000 * 60 * 60 * 8
      );

      if (new Date().getTime() <= checkInExpiry.getTime()) {
        return 'checkedIn';
      }
    }

    if (!activeEvent) {
      return 'checkInNotAvailable';
    }

    return 'checkIn';
  });

  const brandColor = openHome?.location?.report_color || '#000';

  const isBlackOrWhite =
    brandColor.startsWith('#000') || brandColor.startsWith('#fff');

  const tokens = useMemo(
    () => ({
      palette: {
        brand: {
          '50': isBlackOrWhite ? brandColor : setLightness(brandColor, '95%'),
          '100': isBlackOrWhite ? brandColor : setLightness(brandColor, '90%'),
          '200': isBlackOrWhite ? brandColor : setLightness(brandColor, '80%'),
          '300': isBlackOrWhite ? brandColor : setLightness(brandColor, '70%'),
          '400': isBlackOrWhite ? brandColor : setLightness(brandColor, '60%'),
          '500': isBlackOrWhite ? brandColor : setLightness(brandColor, '50%'),
          '600': isBlackOrWhite ? brandColor : setLightness(brandColor, '40%'),
          '700': isBlackOrWhite ? brandColor : setLightness(brandColor, '30%'),
          '800': isBlackOrWhite ? brandColor : setLightness(brandColor, '20%'),
          '900': isBlackOrWhite ? brandColor : setLightness(brandColor, '15%')
        }
      },
      color: {
        textStyle: { primary: { contrast: getContrastColor(brandColor) } }
      },
      loadingSpinner: { colors: [brandColor] }
    }),
    [brandColor, isBlackOrWhite]
  );

  const address = openHome.address.formats.street_name_number_w_suburb;
  const handleSuccessfulCheckIn = useCallback(() => {
    setStep('success');
    setDeviceCheckedIn({
      address,
      time: new Date().toISOString()
    });
    setTimeout(() => setStep('checkedIn'), 1500);
  }, [address, setDeviceCheckedIn]);

  const handleCheckInNotAvailable = useCallback(() => {
    setStep('checkInNotAvailable');
  }, []);

  return (
    <StylesProvider tokens={tokens} debug={__DEV__}>
      <Box
        flexDirection='column'
        flex='1'
        alignItems='center'
        style={{ minHeight: '100%' }}
      >
        <Box
          w='100%'
          h='0.8rem'
          style={{
            background: brandColor,
            position: 'fixed' as const,
            zIndex: 10
          }}
        />

        <Box
          pl='2.4rem'
          pr='2.4rem'
          pb='6.4rem'
          h='100%'
          w='100%'
          flex={1}
          justifyContent={'center'}
        >
          {step === 'checkIn' && (
            <CheckInScreen
              openHome={openHome}
              activeEvent={activeEvent}
              privacyPolicy={privacyPolicy}
              onSuccess={handleSuccessfulCheckIn}
              onCheckInNotAvailable={handleCheckInNotAvailable}
            />
          )}

          {step === 'checkInNotAvailable' && (
            <NotAvailableScreen openHome={openHome} activeEvent={activeEvent} />
          )}

          {step === 'success' && <SuccessScreen />}

          {step === 'checkedIn' && (
            <CheckedInScreen openHome={openHome} activeEvent={activeEvent} />
          )}
        </Box>
      </Box>
    </StylesProvider>
  );
}
