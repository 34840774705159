import React, { useEffect } from 'react';
import Box from '@rexlabs/box';
import { Heading, Body, Large, Small } from '@rexlabs/text';
import { PrimaryButton } from '@rexlabs/button';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { navigation, track } from '@rexlabs/analytics';

import ButtonTextOverflow from 'src/components/button-text-overflow';
import { usePersistedState } from 'src/hooks/use-persisted-state';

const defaultStyles = StyleSheet({
  logo: {
    height: '100%',
    maxWidth: '20rem'
  },

  hero: {
    background: ({ token }) => token('palette.brand.500'),
    backgroundSize: 'cover !important'
  },

  card: {
    background: ({ token }) => token('palette.white'),
    borderRadius: '.4rem',
    boxShadow:
      '0rem .4rem .8rem rgba(34, 36, 42, 0.08), 0rem .2rem .8rem rgba(34, 36, 42, 0.12)'
  },

  avatar: {
    backgroundSize: 'cover !important',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    borderRadius: '50%'
  },

  grey: {
    color: ({ token }) => token('palette.grey.500')
  },

  bold: {
    fontWeight: ({ token }) => token('typography.weight.bold')
  },

  noPadding: {
    padding: 0
  }
});

export function CheckedInScreen({ openHome, activeEvent }) {
  const s = useStyles(defaultStyles);

  const [user] = usePersistedState('@checkInApp/checkedInUser');
  const ebrochureLink =
    openHome?.ebrochure_custom_link || openHome?.ebrochure_link;
  const phone = activeEvent?.event_agent?.phone_mobile;
  const email = activeEvent?.event_agent?.email_address;

  useEffect(() => {
    navigation({ page: '/checked-in', openHome, user });
    document.title = `${openHome?.address?.formats?.street_name_number_w_suburb}`;
  }, []); // eslint-disable-line

  return (
    <Box>
      <Box justifyContent='center' alignItems='center' h='7.2rem' p='1.6rem'>
        <img
          {...s('logo')}
          src={openHome?.location?.logo_image?.url}
          alt={`${openHome?.location?.location_name} logo`}
        />
      </Box>

      <a
        href={ebrochureLink}
        target='_blank'
        rel='noopener noreferrer nofollow'
        onClick={() =>
          track({
            eventName: 'Ebrouchure clicked',
            data: {
              action: 'ebrouchure_clicked',
              section: '/checked-in',
              activeEvent,
              openHome,
              user
            }
          })
        }
      >
        <Box
          h='22rem'
          m='0 -2.4rem'
          {...s.with('hero')({
            background: `url(${openHome?.images?.[0]?.thumbs?.['400x300']}) no-repeat center center`
          })}
        />
      </a>

      <Box mt='-4rem' sy='2.4rem'>
        <Box m='0 -1rem 1.2rem' p='1.6rem 2.4rem' sy='0.5rem' {...s('card')}>
          <Heading level={3} {...s('grey')}>
            Thanks for checking in!
          </Heading>
          <Heading level={2} {...s('bold')}>
            {openHome?.address?.formats?.street_name_number_w_suburb}
          </Heading>
        </Box>

        <ButtonTextOverflow>
          <PrimaryButton
            as='a'
            href={ebrochureLink}
            target='_blank'
            rel='noreferrer nofollow'
            onClick={() =>
              track({
                eventName: 'Ebrouchure clicked',
                data: {
                  action: 'ebrouchure_clicked',
                  section: 'checked-in',
                  activeEvent,
                  openHome,
                  user
                }
              })
            }
          >
            More info about {openHome?.address?.formats?.street_name_number}
          </PrimaryButton>
        </ButtonTextOverflow>

        <Box>
          <Box flexDirection='row' mb='1.6rem'>
            <Box sy='.5rem' style={{ flex: '1 1 auto' }}>
              <Small {...s('grey', 'noPadding')}>Hosted by</Small>
              <Large semibold {...s('noPadding')}>
                {activeEvent?.event_agent?.name}
              </Large>
              <Body {...s('noPadding')}>
                {activeEvent?.event_agent?.position &&
                  activeEvent?.event_agent?.position + ','}{' '}
                {openHome?.location?.location_name}
              </Body>
            </Box>

            {!!activeEvent?.event_agent?.profile_image?.thumbs?.['200x250'] && (
              <Box
                justifyContent='center'
                w='6.4rem'
                h='6.4rem'
                {...s.with('avatar')({
                  backgroundImage: `url(${activeEvent.event_agent.profile_image.thumbs['200x250']?.url})`
                })}
              />
            )}
          </Box>

          <Box flexDirection='row'>
            <Box mr='1rem' sy='3.2rem'>
              {!!phone && (
                <Body semibold {...s('grey', 'noPadding')}>
                  mobile
                </Body>
              )}
              {!!email && (
                <Body semibold {...s('grey', 'noPadding')}>
                  email
                </Body>
              )}
            </Box>
            <Box sy='3.2rem'>
              {!!phone && (
                <Body {...s('noPadding')}>
                  <a
                    href={`tel: + ${phone}`}
                    onClick={() =>
                      track({
                        eventName: 'Agent phone clicked',
                        data: {
                          action: 'agent_phone_clicked',
                          section: 'checked-in',
                          activeEvent,
                          openHome,
                          user
                        }
                      })
                    }
                  >
                    {phone}
                  </a>
                </Body>
              )}
              {!!email && (
                <Body {...s('noPadding')}>
                  <a
                    href={`mailto:${email}`}
                    onClick={() =>
                      track({
                        eventName: 'Agent email clicked',
                        data: {
                          action: 'agent_email_clicked',
                          section: '/checked-in',
                          activeEvent,
                          openHome,
                          user
                        }
                      })
                    }
                  >
                    {email}
                  </a>
                </Body>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
