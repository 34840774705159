import React from 'react';
import Box from '@rexlabs/box';
import { StyleSheet, styled, StylesProps } from '@rexlabs/styling';
import LoadingSpinner from '@rexlabs/loading-spinner';

const defaultStyles = StyleSheet({
  container: {
    width: '100vw',
    height: '100vh',
    color: ({ token }) => token('palette.neutral.50'),
    fontSize: '2rem'
  }
});

export const LoadingLayout = styled(defaultStyles)(
  ({ styles: s }: StylesProps<typeof defaultStyles>) => {
    return (
      <Box {...s('container')} alignItems='center' justifyContent='center'>
        <LoadingSpinner size={16} strokeWidth={3} />
      </Box>
    );
  }
);
