/**
 * NOTE: Button from vivid puts children in a span providing
 * no way to control text overflow. This component will chop
 * the children and put ellipsis. Currently setup to target
 * link buttons i.e. `<PrimaryButton as='a'>...`
 */
import React from 'react';
import { StyleSheet, useStyles } from '@rexlabs/styling';

const defaultStyles = StyleSheet({
  container: {
    '& a span': {
      display: 'block',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  }
});

export default function ButtonTextOverflow(props) {
  const { children } = props;
  const s = useStyles(defaultStyles, 'buttonTextOverflow');

  return <span {...s('container')}>{children}</span>;
}
