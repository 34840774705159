/*
|-------------------------------------------------------------------------------
| App "Theme" for Internal Components
|-------------------------------------------------------------------------------
|
| These theming configurations are suppose to be consumed by `element-style`s
| "StylesProvider". See https://git.io/vNyT4 for more details.
|
| They are kept in a separate module, so we can do fine-grained import-order for
| the App as well as for Storybook Stories.
|
*/

export const COMPONENTS = {};
