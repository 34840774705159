/*
|-------------------------------------------------------------------------------
| API Client
|-------------------------------------------------------------------------------
|
| The singleton API client is created with @rexlabs/api-client. It includes:
|  - Base URL sourced from the env config
|  - Default headers for laravel servers
|  - Middleware (interceptors) for common tasks
|
*/

import { create } from '@rexlabs/api-client';
import config from 'shared/utils/config';

function printError(response) {
  if (response.problem) {
    const trace = response?.data?.trace || [];

    const e: any = new Error(response?.data?.message);
    e.serverCode = response?.data?.code;
    e.serverStack =
      `${response?.data?.exception} in ${response?.data?.file}:${response?.data?.line}\n` +
      trace
        .map(
          (trace) =>
            `at ${trace.function} (${trace.class}) in ${trace.file}:${trace.line}`
        )
        .join('\n');
    e.statusCode = response?.status;
    e.statusText = response?.data?.error?.message;

    console.error('API Error:', response.data);
    console.error(e);

    return Promise.reject(e);
  }

  return response;
}

const clientConfig = {
  baseURL: config.API_URL
};
// TODO: type properly
const api: any = create(clientConfig);
api.setHeader('Accept', 'application/json');
api.setHeader('Content-Type', 'application/json');
api.setHeader('X-App-Identifier', 'Rexlabs:Rex:CheckInApp');
api.addResponseInterceptor(printError);

api.setBaseUrl = (baseURL) => {
  api.axiosInstance.defaults.baseURL = baseURL;
};

function setAuthToken(apiToken) {
  api.setHeader('Authorization', `Bearer ${apiToken}`);
}

function setAccountId(accountId) {
  api.setHeader('X-Account-Id', accountId);
}

export { api, setAuthToken, setAccountId };
