import React, { useEffect } from 'react';
import Box from '@rexlabs/box';
import { StyleSheet, styled, StylesProps } from '@rexlabs/styling';
import { Heading } from '@rexlabs/text';
import { navigation } from '@rexlabs/analytics';

const defaultStyles = StyleSheet({
  container: {
    width: '100vw',
    height: '100vh'
  },

  bold: {
    fontWeight: ({ token }) => token('typography.weight.bold')
  },

  grey: {
    color: ({ token }) => token('palette.grey.500')
  }
});

interface ErrorLayoutProps {
  error: any;
}

export const ErrorLayout = styled(defaultStyles)(
  ({
    styles: s,
    error
  }: ErrorLayoutProps & StylesProps<typeof defaultStyles>) => {
    useEffect(() => {
      navigation({ page: '/something-went-wrong', error });
      document.title = 'Something went wrong';
    }, [error]);

    return (
      <Box {...s('container')} alignItems='center' justifyContent='center'>
        <Box p='2.4rem'>
          <Heading level={2} {...s('bold')}>
            Something went wrong
          </Heading>
          <Heading level={3} {...s('grey')}>
            Please scan the QR code again or speak to your agent
          </Heading>
        </Box>
      </Box>
    );
  }
);
