function isHex(color) {
  return color.startsWith('#');
}

function isRgb(color) {
  return color.startsWith('rgb');
}

export function hexToRGB(h: string) {
  let r = '0';
  let g = '0';
  let b = '0';

  // 3 digits shorthand hex code
  if (h.length === 4) {
    r = '0x' + h[1] + h[1];
    g = '0x' + h[2] + h[2];
    b = '0x' + h[3] + h[3];

    // 6 digits full hex code
  } else if (h.length === 7) {
    r = '0x' + h[1] + h[2];
    g = '0x' + h[3] + h[4];
    b = '0x' + h[5] + h[6];
  }

  return 'rgb(' + +r + ',' + +g + ',' + +b + ')';
}

export function RGBToHSL(rgb) {
  const sep = rgb.indexOf(',') > -1 ? ',' : ' ';
  rgb = rgb.substr(4).split(')')[0].split(sep);

  for (const R in rgb) {
    const r = rgb[R];
    if (r.indexOf('%') > -1)
      rgb[R] = Math.round((r.substr(0, r.length - 1) / 100) * 255);
  }

  // Make r, g, and b fractions of 1
  const r = rgb[0] / 255;
  const g = rgb[1] / 255;
  const b = rgb[2] / 255;

  // Find greatest and smallest channel values
  const cmin = Math.min(r, g, b);
  const cmax = Math.max(r, g, b);
  const delta = cmax - cmin;
  let h = 0;
  let s = 0;
  let l = 0;

  // Calculate hue
  // No difference
  if (delta === 0) h = 0;
  // Red is max
  else if (cmax === r) h = ((g - b) / delta) % 6;
  // Green is max
  else if (cmax === g) h = (b - r) / delta + 2;
  // Blue is max
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  // Make negative hues positive behind 360°
  if (h < 0) h += 360;

  // Calculate lightness
  l = (cmax + cmin) / 2;

  // Calculate saturation
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));

  // Multiply l and s by 100
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  return 'hsl(' + h + ',' + s + '%,' + l + '%)';
}

export function HexToHSL(h: string) {
  return RGBToHSL(hexToRGB(h));
}

function getHSLValues(color) {
  let hsl = color;

  if (isHex(color)) {
    hsl = RGBToHSL(hexToRGB(color));
  } else if (isRgb(color)) {
    hsl = RGBToHSL(color);
  }

  const sep = hsl.indexOf(',') > -1 ? ',' : ' ';
  hsl = hsl.substr(4).split(')')[0].split(sep);

  const h = hsl[0];
  const s = hsl[1].substr(0, hsl[1].length - 1);
  const l = hsl[2].substr(0, hsl[2].length - 1);

  return [h, s, l];
}

export function setLightness(color, lightness) {
  if (!color) return;

  const [h, s] = getHSLValues(color);

  return 'hsl(' + h + ',' + s + '%,' + lightness + ')';
}

export function lighten(color, step: number) {
  const [h, s, l] = getHSLValues(color);
  const lightness = parseInt(l) + step;

  return 'hsl(' + h + ',' + s + '%,' + lightness + '%)';
}

export function getContrastColor(hexColor, greyScale = 128) {
  if (!hexColor || !isHex(hexColor)) {
    return hexColor;
  }

  if (hexColor.length === 4) {
    const split = hexColor.split('#');
    hexColor = ['#', split[1], split[1]].join('');
  }

  const r = parseInt(hexColor.slice(1, 3), 16);
  const g = parseInt(hexColor.slice(3, 5), 16);
  const b = parseInt(hexColor.slice(5, 7), 16);

  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= greyScale ? 'black' : 'white';
}
