/*
|-------------------------------------------------------------------------------
| App Setup
|-------------------------------------------------------------------------------
|
| Connects the DOM to our App. Exposes core App data at DEV time.
|
| It also should be the only file that is importing the store - all other usages
| of store are through react connectors or middleware.
|
*/

import 'core-js';
import 'utils/globals';
import config from 'shared/utils/config';

import React, { useEffect } from 'react';
import { RecoilRoot } from 'recoil';
import { Provider } from 'react-redux';
import { StylesProvider } from '@rexlabs/styling';
import { TextProvider } from '@rexlabs/text';
import { DialogTarget } from '@rexlabs/dialog';
import { PersistGate } from 'redux-persist/integration/react';

import { api } from 'utils/api-client';
import { persistor, store } from 'src/store';
import { initWithSegmentHandler } from '@rexlabs/analytics';
import { includeKeys, transformData } from 'utils/analytics';
import { App } from 'view/app';

import { TOKENS } from 'src/theme/tokens';
import { init } from 'src/theme/init';
import { COMPONENTS } from 'src/theme/components';
import { createRoot } from 'react-dom/client';

if (__DEV__) {
  window.app = {
    store,
    api
  };
}

initWithSegmentHandler({
  id: config.CHECK_IN_APP_SEGMENT_ID,
  appName: 'CheckInApp',
  transformData: (data) => transformData(data, includeKeys)
});

// We're handling bugsnag separate to Segment atm
if (config.CHECK_IN_APP_BUGSNAG_ID && window.bugsnag) {
  try {
    window.bugsnagClient = window.bugsnag({
      beforeSend: (report) => {
        // We want to ignore `ResizeObserver loop limit exceeded` errors, since this is
        // just a browser warning that is unfortunately handled through `window.onerror`
        // and is expected behaviour :/
        // See https://github.com/WICG/resize-observer/issues/38#issuecomment-334816361
        if (
          [
            'ResizeObserver loop limit exceeded',
            'ResizeObserver loop completed with undelivered notifications.'
          ].includes(report.errorMessage)
        ) {
          report.ignore();
        }
      },
      apiKey: config.CHECK_IN_APP_BUGSNAG_ID,
      appVersion: config.RELEASE?.VERSION,
      releaseStage: config.RELEASE?.STAGE || 'local'
    });
  } catch (e) {
    console.error(`Bugsnag configuration variables are invalid:\n${e}`);
  }
}

// Setup global parts of theme
init({ tokens: TOKENS, loadTypekit: true });

function CheckInApp() {
  useEffect(() => {
    window.document.body.classList.add('ready');
  }, []);

  return (
    <>
      {/* Very old recoil version that has type issues with React 18 */}
      {/* @ts-ignore */}
      <RecoilRoot>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <StylesProvider
              components={COMPONENTS}
              tokens={TOKENS}
              debug={__DEV__}
            >
              <TextProvider>
                <>
                  <DialogTarget />
                  <App />
                </>
              </TextProvider>
            </StylesProvider>
          </PersistGate>
        </Provider>
      </RecoilRoot>
    </>
  );
}

const root = createRoot(document.getElementById('app') as HTMLElement);
root.render(<CheckInApp />);
