import _ from 'lodash';
import { Generator } from 'shared/utils/models';
import { api } from 'src/utils/api-client';

const initialState = {
  ready: false,
  error: false,
  openHome: null,
  activeEvent: null,
  gtmId: null,
  facebookPixelId: null
};

const selectors = {
  ready: (state) => state.session.ready,
  apiToken: (state) => state.session.apiToken,
  user: (state) => state.session.user
};

async function initSession(getState) {
  // Get token and region id from the url
  try {
    const { whereabouts } = getState();
    const currentLocation = whereabouts.locations[whereabouts.current];
    const token = currentLocation?.query?.token;
    const region = currentLocation?.query?.region;

    if (!token || !region) {
      return { error: new Error('Invalid URL') };
    }

    // Get region base url
    const regionResponse = await api.post('/region/get-regions');
    const regionBase = regionResponse?.data?.result?.[region]?.base_url;

    if (!regionBase) {
      return { error: new Error('Region not found') };
    }

    // Set region base url in api client for any future api calls
    api.setBaseUrl(regionBase + '/v1/rex');

    // Get check in information for given token
    const response = await api.post('/open-home-check-ins/read', { token });
    const listing = response.data?.result?.listing;
    const activeEvent = response.data?.result?.active_event;
    const privacyPolicy = response.data?.result?.privacy_policy_url;
    const gtmId = response.data?.result?.marketing_pixels?.find(
      (mp) => mp.type === 'gtm_container_id'
    )?.value;
    const facebookPixelId = response.data?.result?.marketing_pixels?.find(
      (mp) => mp.type === 'facebook_pixel_id'
    )?.value;

    return {
      openHome: listing,
      activeEvent,
      privacyPolicy,
      gtmId,
      facebookPixelId
    };
  } catch (error) {
    return { error };
  }
}

const actionCreators = {
  login: {
    request: (payload) => new Promise((resolve) => resolve({ user: payload })),
    reduce: {
      initial: _.identity,
      success: (state, action) => ({
        ...state,
        user: action.payload.user,
        apiToken: action.payload.apiToken
      }),
      failure: (state) => ({
        ...state,
        apiToken: null
      })
    }
  },

  logout: {
    reduce: (state) => ({
      ...state,
      user: null,
      apiToken: null
    })
  },

  init: {
    request: (payload, actions, dispatch, getState) => initSession(getState),
    reduce: {
      initial: _.identity,
      success: (state, action) => ({
        ...state,
        ready: true,
        error: action.payload?.error,
        openHome: action.payload?.openHome,
        activeEvent: action.payload?.activeEvent,
        privacyPolicy: action.payload?.privacyPolicy,
        gtmId: action.payload?.gtmId,
        facebookPixelId: action.payload?.facebookPixelId
      }),
      failure: (state) => ({
        ...state,
        ready: true,
        apiToken: null
      })
    }
  }
};

export const sessionModel = new Generator<any>('session').createModel({
  initialState,
  selectors,
  actionCreators
});
