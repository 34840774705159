import { TOKENS as DEFAULTS } from '@rexlabs/theme-luna';
import { merge } from 'lodash';

export const TOKENS = merge(
  // Default luna theme
  DEFAULTS,

  // Check In App specific overrides
  {
    button: {
      width: '100%'
    }
  }
);
