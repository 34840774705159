/*
|-------------------------------------------------------------------------------
| Redux Utils
|-------------------------------------------------------------------------------
|
| - Construct the store with DX Tools setup
|   - Add thunk middleware
|   - Add enhancements for redux devtools
| - Middleware for adding deferred auth details to the API Client
|
*/

import {
  applyMiddleware,
  compose,
  createStore,
  Middleware,
  Store,
  StoreEnhancer
} from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

type Composer = (...middleware: StoreEnhancer[]) => StoreEnhancer;

/**
 * Creates an enhanced store middleware, out of a stack of middleware.
 * Injects a debugger middleware when in DEV mode.
 * @param {Function} enhancers - A middleware stack.
 * @returns {Function}
 */
function createEnhancer(...enhancers: StoreEnhancer[]): StoreEnhancer {
  const composer: Composer = __DEV__ ? composeWithDevTools : compose;
  return composer(...enhancers);
}

/**
 * Creates a stack of middleware.
 * Injects redux thunk middleware
 * @param {Object} middlewares - a collection of middleware
 * @returns {Function}
 */
function createMiddlewareStack(middlewares) {
  const middlewareStack = [thunkMiddleware, ...middlewares];
  return applyMiddleware(...middlewareStack);
}

/**
 * Creates a redux store with the following properties:
 * 1. Support for Redux DevTools and most monitor implementations (DEV only).
 * 2. Middleware
 *     - redux-thunk
 *
 * @param {Redux.Reducer} rootReducer - The top level reducer, to used in
 *        the redux store.
 * @param {Object} [middlewares] - Other middleware to apply to the store.
 * @param {Boolean} [isLogging=false] - Whether to log to redux actions to console.
 * @returns {Redux.Store}
 */
export function configureStore(
  rootReducer,
  middlewares: Middleware[] = [],
  enhancers: StoreEnhancer[] = []
): Store {
  // TODO: fix types - https://app.shortcut.com/rexlabs/story/60659
  const initialState = {} as any;

  return createStore(
    rootReducer,
    initialState,
    createEnhancer(createMiddlewareStack(middlewares), ...enhancers)
  );
}
