if (!window) {
  // eslint-disable-next-line
  window = window || global;
}

if (window) {
  window.__DEV__ =
    typeof window.__DEV__ !== 'undefined'
      ? window.__DEV__
      : process.env.NODE_ENV !== 'production';
}
