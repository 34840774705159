import React from 'react';
import { StyleSheet, keyframes, useStyles } from '@rexlabs/styling';

const CHECK_LENGTH = 128;

const animateCheck = keyframes({
  '0%': { strokeDashoffset: 1 * CHECK_LENGTH },
  '100%': { strokeDashoffset: 0 * CHECK_LENGTH }
});

const animateCheckBounce = keyframes({
  '0%': {
    transform: 'scale(0.5) rotate(30deg)',
    opacity: 0
  },
  '85%': {
    transform: 'scale(1.2) rotate(0deg)',
    opacity: 1
  },
  '100%': { transform: 'scale(1) rotate(0deg)' }
});

const animateCircleStroke = keyframes({
  '0%': {
    strokeDashoffset: 3.14 * 98 * 2,
    opacity: 0
  },
  '100%': {
    strokeDashoffset: 0,
    opacity: 1
  }
});

const animateCircleBg = keyframes({
  '0%': { backgroundColor: 'transparent' },
  '100%': { backgroundColor: '#C2F0D1' }
});

const defaultStyles = StyleSheet({
  container: {
    position: 'relative',
    width: '200px',
    height: '200px',
    borderRadius: '50%',
    backgroundColor: '#C2F0D1',
    opacity: 1,
    animation: `${animateCircleBg} 0.2s 0.4s both ease-in-out 1`
  },

  circle: {
    position: 'absolute',
    transform: 'rotate(90deg) scale(-1, 1)',
    strokeDasharray: 3.14 * 98 * 2,
    animation: `${animateCircleStroke} 0.6s both ease-out 1`
  },

  check: {
    position: 'absolute',
    top: '58px',
    left: '47px',
    transformOrigin: '53px 41px 0',
    animation: `${animateCheckBounce} 0.3s 0.1s both ease-in-out 1`,

    '& path': {
      strokeDasharray: CHECK_LENGTH,
      animation: `${animateCheck} 0.45s 0.1s both ease-in-out 1`
    }
  }
});

function Tick() {
  const s = useStyles(defaultStyles, 'Tick');

  return (
    <div {...s('container')}>
      <svg
        {...s('circle')}
        width='200'
        height='200'
        viewBox='0 0 200 200'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <circle cx='100' cy='100' r='98' stroke='#C2F0D1' strokeWidth='4' />
      </svg>

      <svg
        {...s('check')}
        width='106'
        height='83'
        viewBox='0 0 106 83'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path d='M8 38L38 68L98 8' stroke='#103C1F' strokeWidth='20' />
      </svg>
    </div>
  );
}

export default Tick;
