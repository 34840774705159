import React from 'react';
import { useModelState } from '@rexlabs/model-generator';
import GoogleTagManager from '@rexlabs/analytics/lib/providers/google-tag-manager';
import FacebookPixel from '@rexlabs/analytics/lib/providers/facebook-pixel';

import { sessionModel } from 'data/models/custom/session';
import { LoadingLayout } from 'src/layouts/loading';
import { ErrorLayout } from 'src/layouts/error';
import { AppLayout } from 'src/layouts/app';

export function App() {
  // TODO: fix types - https://app.shortcut.com/rexlabs/story/60659
  const { ready, error, gtmId, facebookPixelId } = useModelState(
    sessionModel
  ) as any;

  if (error) {
    return <ErrorLayout error={error} />;
  }

  if (!ready) {
    return <LoadingLayout />;
  }

  return (
    <>
      {!!gtmId && <GoogleTagManager id={gtmId} />}
      {!!facebookPixelId && <FacebookPixel id={facebookPixelId} />}
      <AppLayout />
    </>
  );
}
