/*
|-------------------------------------------------------------------------------
| Singleton App Store (Redux)
|-------------------------------------------------------------------------------
|
| It's inclusion in other modules in the App should ideally come from dependency
| injection techniques to keep those files testable.
|
*/

import { combineReducers } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { configureStore } from 'utils/redux';
import {
  whereaboutsMiddleware,
  whereaboutsReducer as whereabouts
} from '@rexlabs/whereabouts';

/*
| Core Models
|------------------------
*/
import { sessionModel as session } from 'data/models/custom/session';

/*
 | Entity Models (Model Generator)
 |------------------------
 */

/*
| Setup for Store (incl. persistance)
|------------------------
*/
const persistSession = persistReducer(
  {
    key: '@check-in-app/session',
    storage,
    blacklist: ['ready']
  },
  session
);

const reducers = combineReducers({
  session: persistSession,
  whereabouts
});

const store = configureStore(reducers, [whereaboutsMiddleware]);
const persistor = persistStore(store, null, () => {
  store.dispatch(session.actionCreators.init());
});

if (__DEV__) {
  window.purgePersistedStore = persistor.purge;
}

export { store, persistor };
