import { useState, useEffect } from 'react';

export function usePersistedState(key: string, defaultState?: any) {
  const localStorage = window.localStorage.getItem(key);
  const initialState = localStorage
    ? JSON.parse(localStorage)?.value
    : undefined;

  const [state, setState] = useState(initialState ?? defaultState);
  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify({ value: state }));
  }, [key, state]);

  return [state, setState];
}
