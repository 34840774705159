import React, { useEffect, useRef, useState } from 'react';
import Box from '@rexlabs/box';
import { createValidationRules } from '@rexlabs/validator';
import { Heading, Body, Small } from '@rexlabs/text';
import { ReactForms, Form, Field } from '@rexlabs/form';
import { TextInput, EmailInput, PhoneInput } from '@rexlabs/text-input';
import { PrimaryButton } from '@rexlabs/button';
import LoadingSpinner from '@rexlabs/loading-spinner';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { navigation, error as trackError, track } from '@rexlabs/analytics';

import Footer from 'src/components/footer';
import { api } from 'src/utils/api-client';
import { usePersistedState } from 'src/hooks/use-persisted-state';

const defaultStyles = StyleSheet({
  container: {
    width: '100%',
    position: 'relative'
  },

  loadingContainer: {
    background: ({ token }) => token('palette.white'),
    outline: '.1rem solid white',
    position: 'absolute',
    top: 0,
    zIndex: 5
  },

  spinner: {
    width: '1.6rem',
    height: '1.6rem'
  },

  divider: {
    background: ({ token }) => token('palette.grey.300')
  },

  error: {
    padding: 0,
    color: ({ token }) => token('palette.red.500')
  },

  grey: {
    color: ({ token }) => token('palette.grey.500')
  },

  bold: {
    fontWeight: ({ token }) => token('typography.weight.bold')
  }
});

// NOTE: had to add 'as any' otherwise ReactForms would get a type error
const validation = createValidationRules({
  first_name: ['required', 'first name'],
  last_name: ['required', 'last name'],
  email: ['required|email', 'email'],
  phone: 'required',
  postcode: 'min:4|max:4'
}) as any;

export function CheckInScreen(props) {
  const s = useStyles(defaultStyles);

  const { openHome, activeEvent, privacyPolicy } = props;
  const [user, setCheckedInUser] = usePersistedState(
    '@checkInApp/checkedInUser'
  );
  const [error, setError] = useState(false);
  const hasStartedForm = useRef(false);

  const handleSubmit = async (values) => {
    const { onSuccess, onCheckInNotAvailable } = props;
    const { email, phone, first_name, last_name, ...restValues } = values;
    const user = {
      ...restValues,
      firstName: first_name,
      lastName: last_name,
      email,
      phone
    };

    try {
      const token = new URLSearchParams(window.location.search).get('token');

      track({
        eventName: 'Check in clicked',
        data: {
          action: 'check_in_clicked',
          section: '/check-in',
          activeEvent,
          openHome,
          user
        }
      });

      await api.post('/open-home-check-ins/check-in', {
        token,
        data: {
          ...restValues,
          name_first: first_name,
          name_last: last_name,
          phones: [{ phone_number: phone }],
          emails: [{ email_address: email }]
        }
      });

      setCheckedInUser(user);

      track({
        eventName: 'Checked in user',
        data: {
          action: 'checked_in_user',
          section: '/check-in',
          activeEvent,
          openHome,
          user
        }
      });

      onSuccess();
    } catch (e: any) {
      // NOTE: on error still want to set the checked in user because if they
      // go to the checkInNotAvailable screen we want to capture the user there
      setCheckedInUser(user);

      if (
        e.statusText === 'Active event required on listing' ||
        e.statusText ===
          'Listing must have an active event to be able to check in attendees'
      ) {
        onCheckInNotAvailable();
        return;
      }

      trackError({
        error: e,
        section: '/check-in',
        activeEvent,
        openHome,
        user
      });

      setError(e.statusText);
    }
  };

  useEffect(() => {
    navigation({ page: '/check-in', activeEvent, openHome, user });
    document.title = `Please check in to ${openHome?.address?.formats?.street_name_number_w_suburb}`;
  }, []); // eslint-disable-line

  return (
    <Box {...s('container')}>
      <Box sy='0.5rem' mt='2.4rem' mb='2.4rem'>
        <Heading level={3} {...s('grey')}>
          Welcome to
        </Heading>
        <Heading level={2} {...s('bold')}>
          {openHome?.address?.formats?.street_name_number_w_suburb}
        </Heading>
        <Body style={{ padding: 0 }}>
          Hosted by {activeEvent?.event_agent?.name} from{' '}
          {openHome?.location?.location_name}
        </Body>
      </Box>

      {error && (
        <Box mb='2.4rem'>
          <Small {...s('error')}>{error}</Small>
        </Box>
      )}

      <ReactForms handleSubmit={handleSubmit} validate={validation}>
        {(formProps) => {
          // @ts-ignore due to issue with errors not existing on the type
          const { submitForm, isSubmitting, isDirty, errors } = formProps;
          const validationErrors = Object.values(errors).filter(Boolean);

          if (!hasStartedForm.current && isDirty) {
            hasStartedForm.current = true;
            track({
              eventName: 'Check in started',
              data: {
                action: 'check_in_started',
                section: '/check-in',
                activeEvent,
                openHome,
                user
              }
            });
          }

          if (validationErrors.length > 0) {
            track({
              eventName: 'Check in validation error triggered',
              data: {
                action: 'check_in_validation_error_triggered',
                section: '/check-in',
                activeEvent,
                openHome,
                user,
                validationErrors
              }
            });
          }

          return (
            <>
              {isSubmitting && (
                <Box
                  w='100%'
                  h='100%'
                  flexDirection='column'
                  justifyContent='center'
                  alignItems='center'
                  {...s('loadingContainer')}
                >
                  <Heading level={3} {...s('grey')}>
                    Checking you in...
                  </Heading>
                  <Box mt='2.4rem'>
                    <LoadingSpinner size={16} strokeWidth={3} />
                  </Box>

                  <Footer openHome={openHome} />
                </Box>
              )}

              <Form style={{ display: isSubmitting ? 'none' : 'block' }}>
                <Field
                  id='first_name'
                  name='first_name'
                  label='First Name'
                  Input={TextInput}
                  fullWidth
                />
                <Field
                  id='last_name'
                  name='last_name'
                  label='Last Name'
                  Input={TextInput}
                  fullWidth
                />
                <Field
                  id='email'
                  name='email'
                  label='Email'
                  Input={EmailInput}
                  fullWidth
                />
                <Field
                  name='phone'
                  label='Phone'
                  Input={PhoneInput}
                  fullWidth
                />
                <Field
                  name='postcode'
                  label='Your Postcode'
                  inputProps={{ type: 'number', inputmode: 'numeric' }}
                  Input={TextInput}
                  fullWidth
                />

                <Box mb='1.2rem'>
                  <Body {...s('grey')}>
                    By checking in you are agreeing to{' '}
                    {privacyPolicy && (
                      <>
                        <a
                          href={privacyPolicy}
                          target='_blank'
                          rel='noreferrer noopener'
                          onClick={() =>
                            track({
                              eventName: 'Agency privacy policy clicked',
                              data: {
                                action: 'agency_privacy_policy_clicked',
                                section: '/check-in',
                                activeEvent,
                                openHome,
                                user
                              }
                            })
                          }
                          {...s('grey')}
                        >
                          our privacy policy
                        </a>{' '}
                        and{' '}
                      </>
                    )}
                    <a
                      href='https://www.rexsoftware.com/legal/privacy'
                      target='_blank'
                      rel='noreferrer noopener'
                      onClick={() =>
                        track({
                          eventName: 'Rex privacy policy clicked',
                          data: {
                            section: '/check-in',
                            action: 'rex_privacy_policy_clicked',
                            activeEvent,
                            openHome,
                            user
                          }
                        })
                      }
                      {...s('grey')}
                    >
                      Rex’s privacy policy
                    </a>
                    .
                  </Body>
                </Box>

                <PrimaryButton onClick={() => submitForm()}>
                  Check in
                </PrimaryButton>
              </Form>
            </>
          );
        }}
      </ReactForms>

      <Footer openHome={openHome} />
    </Box>
  );
}
