import React from 'react';
import Box from '@rexlabs/box';
import { useStyles, StyleSheet } from '@rexlabs/styling';
import { Small } from '@rexlabs/text';

const defaultStyles = StyleSheet({
  divider: {
    background: ({ token }) => token('palette.grey.300')
  },

  logo: {
    height: '100%',
    maxWidth: '20rem'
  },

  noPadding: {
    padding: 0
  }
});

export default function Footer(props) {
  const { openHome } = props;
  const s = useStyles(defaultStyles, 'footer');

  return (
    <Box Container='footer' w='100%'>
      <Box w='100%' h='0.1rem' mt='2.4rem' mb='2.4rem' {...s('divider')} />

      {!!openHome?.location?.logo_image?.url && (
        <Box justifyContent='center' alignItems='center' h='4rem'>
          <img
            {...s('logo')}
            src={openHome.location.logo_image.url}
            alt={`${openHome?.location?.location_name} logo`}
          />
        </Box>
      )}

      <Box flexDirection='column' alignItems='center' mt='.8rem' sy='1.6rem'>
        <Small grey {...s('noPadding')}>
          Listing ID {openHome.id}
        </Small>
        <Small grey {...s('noPadding')}>
          Powered by Rex Software
        </Small>
      </Box>
    </Box>
  );
}
