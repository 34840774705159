import React from 'react';
import Box from '@rexlabs/box';

import Tick from 'src/components/tick';

import checkedInChime from 'assets/checked-in-chime.mp3';

export function SuccessScreen() {
  return (
    <Box w='100%' alignItems='center' justifyContent='center'>
      <audio src={checkedInChime} autoPlay />
      <Tick />
    </Box>
  );
}
